<div id="information-deploy" bsModal #informationDeployModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="informationDeployModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #informationDeployform="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{titleModal}}</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="form-group day-week-content">
                        <div>
                            <textarea class="text-information" readonly>{{returnDeploy}}</textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer buttons">
                    <button type="button" class="btn btn-default" (click)="close()">{{l("Não")}}</button>
                    <button type="button" class="btn btn-default yes"
                        (click)="transportInException()">{{l("Sim")}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
